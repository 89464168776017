<template>
  <div id="export">
    <h1>{{collectionName}}</h1>
    <Editable v-if="exportData" v-model="exportData"></Editable>

    <button @click="download()">Download {{collectionName}}</button>
     &nbsp; &nbsp; &nbsp;
    <button
      v-confirm="{
                  loader: true,
                  ok: dialog => upload(exportData,dialog),
                  cancel: doNothing,
                  message: 'Are you sure you want to update? This could destroy all your data'}"
    >Upload to collection {{collectionName}}</button>
    &nbsp; &nbsp; &nbsp;
    <button @click="convertJSON(exportData)">Download as file</button>
    <button @click="convertCSV(exportData)">Download as CSV</button>
    <!-- <button @click="bootstrap()"> Boot strap system </button> -->
    <h4>Console</h4>
    <div v-html="status"></div>
  </div>
</template>

<script>
import firebase from "firebase";
import Apicall from "../mixins/Apicall";
import Editable from "@/components/Editable";
const { parse } = require('json2csv');
export default {
  name: "collectionExport",
  components: { Editable },
  data() {
    return {
      exportData: {},
      collectionName: "",
      status: ""
    };
  },
  async mounted() {
    this.download();
  },
  methods: {
    doNothing() {},
    async download() {
      this.status += "Downloading collection " + this.collectionName + "<br>";
      this.collectionName = this.$route.params.collectionName;
      let results = await Apicall.getfirebaseCollectionAll(this.collectionName);

      this.status += "Downloading complete for " + this.collectionName + "<br>";

      this.exportData = JSON.stringify(results, null, 4);
    },
    async upload(dataToUpload, dialog) {
      dialog.close();
      let cleanedData = dataToUpload.replace(/(\r\n|\n|\r)/gm, "");
      let collectionData = JSON.parse(cleanedData);

  

      let dataLength = collectionData.length;
      let currentItemCount = 1;

      for (let item of collectionData) {
        this.status +=
          "Updating item " + currentItemCount + " of " + dataLength + "<br>";

        this.status +=
          "Updating collection " +
          this.collectionName +
          " doc id: " +
          item.docId +
          "<br>";

        if (item.docId) {
          // try {
            let results = await Apicall.updatefirebaseCollection(
              this.collectionName,
              item.docId,
              item
            );
          // } 
          //  catch (err) {
          // //   this.status +=
          // //     "<span style='color:red'>" + JSON.stringify(err) + "</span";
          //  }
        
          console.log(results);
        } else {

       
          this.status +=
            "Updating collection " + this.collectionName + " new item <br> ";

         // try {
            let results = await Apicall.addfirebaseCollection(
              this.collectionName,
              item
            );
          // } catch (err) {
          //   this.status +=
          //     "<span style='color:red'>" + JSON.stringify(err) + "</span";
          // }

          
          console.log(results);
        }

        currentItemCount++;
      }
    },
    async bootstrap() 
    {
           let results = await Apicall.bootstrap();
           console.log(results);

    },
    convertJSON(jsonString)
    {
        this.saveFile(jsonString,"export.json","text/json")
    },
    convertCSV(jsonString){
        
        let jsonObj = JSON.parse(jsonString);
        let csv = parse(jsonObj)
      
        this.saveFile(csv,"export.csv","text/csv")
     
    },
    saveFile(dataToConvert,fileName,fileType) {
      const blob = new Blob([dataToConvert], { type: "text/plain" });
      const e = document.createEvent("MouseEvents"),
        a = document.createElement("a");
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = [fileType, a.download, a.href].join(":");

      e.initEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(e);
    }
  }
};
</script>
